import React, { Component } from 'react'; //different
import SweetAlert from 'react-bootstrap-sweetalert';
//import Paoyingchub from './game/paoyingchub';
import jwt_decode from "jwt-decode";
import instance from "./axios-instance";
//import './build/web/igame-index-lobby-wm/style.a57427aa.css';
import { isMobile } from 'react-device-detect';
import NavMenu from './navmenu';
import Swal from 'sweetalert2'
import Systems from "./SystemFunction";

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categorylist: [],
            slotlist: [],
            casinolist: [],
            sportlist: [],
            arcadelist: [],
            lottolist: [],
            fishlist: [],
            partner: null,
            activePage: 1,
            activeid: 0,
            formData: {}, // Contains login form data
            errors: {}, // Contains login field errors
            formSubmitted: false, // Indicates submit status of login form
            loading: false, // Indicates in progress state of login form
            pagecount: 1,
            username: null,
            userid: null,
            point: null,
            pagestate: 0,
            products_page_count: 1,
            loginstate: false,
            firstname: null,
            lastname: null,
            counter: 0,
            alert: null,
            typepage: ""
        }
        // this.handleClick = this.handleClick.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);
        // this.hideAlert = this.hideAlert.bind(this);

    }


    buttoncheck = async (num) => {
        console.log(num, window.innerWidth);
        if (window.innerWidth < 1200) {
            await instance.post("/api/v1/tabgamelist", {
                state: num.toString(),
                System: Systems,
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.setState({
                            partner: num,
                            slotlist: res.data.message
                        });
                    }
                });
        }
        else {
            if (num == 3) return document.getElementById("btnbrand").click();
            if (num == 2) return document.getElementById("btncasino").click();
            if (num == 5) return document.getElementById("btnsport").click();
            if (num == 4) return document.getElementById("btnarcade").click();
            if (num == 8) return document.getElementById("btnfish").click();
            if (num == 11) return document.getElementById("btnlotto").click();
        }
    }



    redeem = async (e, gameIdx, partner) => {
        console.log(partner, this.state.partner, 77);
        var userAgent = navigator.userAgent
        console.log(userAgent);
        this.checklogin();
        if (!this.state.loginstate) {
            console.log(5465656);
            return document.getElementById("loginmode").click();
        }
        if (this.state.username !== null) {
            if (this.state.partner === null) {
                Swal.fire({
                    position: "center",
                    text: 'กำลังเปิดเกม รอซักครู่',
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 800
                });
                await instance.post("/api/v1/tablauncher", {
                    gameId: gameIdx,
                    partner: partner,
                    System: Systems,
                    playerUsername: this.state.userid,
                    isMobile: isMobile,
                    demo: false
                })
                    .then((res) => {
                        if (res.data.status === 200) {
                            //////console.log(res.data.message);
                            var test = res.data.message.launcher
                            if (test !== undefined)
                                if (/iPad|iPhone|Macintosh|iPod/.test(userAgent) && !window.MSStream) {
                                    window.location.href = test.mobile;
                                }
                                else {

                                    if (!isMobile) window.open(test.desktop, '_blank', 'height=1000,width=1000');
                                    else window.open(test.mobile);
                                }
                            else if (test === undefined) {
                                Swal.fire({
                                    title: 'error!',
                                    text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                    icon: 'error',
                                    confirmButtonText: 'ตกลง'
                                })
                            }
                        }
                        else {
                            Swal.fire({
                                title: 'error!',
                                text: 'ขณะนี้เกมมีปัญหากรุณารอซักครู่',
                                icon: 'error',
                                confirmButtonText: 'ตกลง'
                            })
                            //  this.CheckThisGoalError();
                        }
                    });
            }
            else {
                if (this.state.partner == 3) window.location.href = '/listgameall/slot/' + partner;
                else if (this.state.partner == 2) window.location.href = '/listgameall/casino/' + partner;
                else if (this.state.partner == 5) window.location.href = '/listgameall/sport/' + partner;
                else if (this.state.partner == 4) window.location.href = '/listgameall/arcade/' + partner;
                else if (this.state.partner == 8) window.location.href = '/listgameall/fishing/' + partner;
                else if (this.state.partner == 11) window.location.href = '/listgameall/lotto/' + partner;
            }
        }
        else {

        }


    }
    onError = (img) => {
        console.log(img)
        return img
    }
    checklogin() {
        var token = localStorage.getItem('auth_token');
        if (token != null) {
            var decoded = jwt_decode(token);
            //////console.log(decoded);
            this.setState({
                userid: decoded.message.playerid,
                username: decoded.message.playerid,
                firstname: decoded.message.firstname,
                lastname: decoded.message.lastname,
                point: decoded.message.Point,
                loginstate: true,
            });
        }
        else {
            this.setState({
                loginstate: false,
            });
        }
    }

    componentDidMount() {
        this.checklogin();
        this.checkonline();
    }
    reload(e, partner, game) {

        window.location.href = "/listgameall/" + game + "/" + partner;
        // this.setState({
        //     pagecount: 1,
        // });
        // instance.post("/api/v1/tabgamelistpop", {
        //     state: "999",
        //     System: Systems,
        //     partner: partner,
        //     game: game
        // })
        //     .then((res) => {
        //         console.log(res.data);
        //         if (res.data.status === 200) {
        //             this.setState({
        //                 slotlist: res.data.message
        //             });
        //         }
        //         else {
        //             //  this.CheckThisGoalError();
        //         }
        //     });


    }
    checkonline() {

        const search = window.location.href;
        const params = new URLSearchParams(search);
        const space = search.split("/")
        //  console.log(1,space[5].replace("#", ""));
        if (space[5].replace("#", "") !== "") {
            console.log(2);
            this.setState({
                typepage: space[4].replace("#", ""),
            });
            instance.post("/api/v1/tabgamelist", {
                state: "999",
                System: Systems,
                partner: space[5].replace("#", ""),
                game: space[4].replace("#", ""),
            })
                .then((res) => {
                    // console.log(213,res);
                    if (res.data.status === 200) {
                        //  console.log(213,res.data);
                        this.setState({
                            slotlist: res.data.message
                        });
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });

            this.setState({
                typepage: space[4].replace("#", ""),
            });

        }
        else {
            console.log(3);
            this.setState({
                typepage: space[4].replace("#", ""),
            });
            instance.post("/api/v1/tabgamelistpop", {
                state: "7",
                System: Systems,
                Type: 2,
                game: space[4].replace("#", ""),
            })
                .then((res) => {
                    if (res.data.status === 200) {
                        console.log(234, res.data);
                        this.setState({
                            slotlist: res.data.message
                        });
                    }
                    else {
                        //  this.CheckThisGoalError();
                    }
                });

            this.setState({
                typepage: space[4].replace("#", ""),
            });
        }

        instance.post("/api/v1/tabgamelist", {
            state: "3",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        categorylist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "2",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        casinolist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "5",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        sportlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });

        instance.post("/api/v1/tabgamelist", {
            state: "8",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        fishlist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "4",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        arcadelist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        instance.post("/api/v1/tabgamelist", {
            state: "11",
            System: Systems,
        })
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({
                        lottolist: res.data.message,
                        //  counter: res.data.counter,
                    });
                }
                else {
                    //  this.CheckThisGoalError();
                }
            });
        // if (this.state.pagestate === 0) {

        //     instance.post("/api/v1/tabgamelistpop", {
        //         state: "7",
        //         System: Systems,
        //         Type: 2,
        //     })
        //         .then((res) => {
        //             if (res.data.status === 200) {
        //                 this.setState({
        //                     slotlist: res.data.message,
        //                 });
        //             }
        //             else {
        //                 //  this.CheckThisGoalError();
        //             }
        //         });
        // }
    }

    // copyToClipboard = (e) => {
    //     e.preventDefault();
    //     var categorylist = this.state.categorylist;
    //     let searchStr = this.state.formData["sec"];
    //     //////console.log(searchStr);
    //     //////console.log(categorylist);
    //     if (searchStr !== "") {
    //         let matches = categorylist.filter(t => t.description.toLowerCase().includes(searchStr));
    //         //////console.log(matches);
    //         this.setState({
    //             categorylist: matches,
    //         });
    //     } else {
    //         this.checkonline();
    //     }
    // };

    // handleInputChange = (event) => {
    //     const target = event.target;
    //     const value = target.value;
    //     const name = target.name;
    //     //////console.log(value);
    //     let { formData } = this.state;
    //     formData[name] = value;
    //     this.setState({
    //         formData: formData,
    //     });
    // }


    render() {
        if (this.state.categorylist.length > 0) {
            return (
                <div>
                    <NavMenu />
                    <section className="x-category-index -v2">
                        <div className="-nav-menu-container js-category-menus -sticky">
                            <nav className="nav-menu" id="navbarCategory">
                                <ul
                                    className="-menu-parent navbar-nav js-menu-container"
                                    id="accordion-games"
                                >
                                    <li className="-list-parent nav-item">
                                        <div className="d-lg-block d-none">
                                            <a
                                                href="/listgameall/slot/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-popular  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-popular.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-popular.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -default"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-popular.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-popular-hover.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-popular-hover.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -hover"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-popular-hover.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">ยอดนิยม</span>
                                            </a>
                                        </div>
                                        <div className="d-lg-none d-block w-100">
                                            <a
                                                href="/listgameall/slot/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-popular  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-popular-mobile.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-popular-mobile.png?v=1"
                                                    />
                                                    <img
                                                        alt="cover image png"
                                                        className="-img-mobile"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-popular-mobile.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">ยอดนิยม</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item">
                                        <div className="d-lg-block d-none">
                                            <a
                                                href="/listgameall/slot/"
                                                data-target=".js-game-container"
                                                data-href-push-state="/games"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-new-released active -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-new-released.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-new-released.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -default"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-new-released.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-new-released-hover.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-new-released-hover.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -hover"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-new-released-hover.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">เกมใหม่</span>
                                            </a>
                                        </div>
                                        <div className="d-lg-none d-block w-100">
                                            <a
                                                href="/listgameall/slot/"
                                                data-target=".js-game-container"
                                                data-href-push-state="/games"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-new-released active -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-new-released-mobile.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-new-released-mobile.png?v=1"
                                                    />
                                                    <img
                                                        alt="cover image png"
                                                        className="-img-mobile"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-new-released-mobile.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">เกมใหม่</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item">
                                        <a
                                            href="/listgameall/casino/"
                                            data-target=".js-game-container"
                                            data-loading="_onLoading_"
                                            data-collapse-menu="js-brand"
                                            className="x-category-button d-lg-none js-brand -category-brand  -category-button-v2 -hoverable "
                                        >
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="/build/web/ez-bet-v2/img/ez-slot-category-brand-mobile.webp?v=3"
                                                />
                                                <source
                                                    type="image/png?v=1"
                                                    srcSet="/build/web/ez-bet-v2/img/ez-slot-category-brand-mobile.png?v=1"
                                                />
                                                <img
                                                    alt="cover image png"
                                                    className="-img-mobile"
                                                    src="/build/web/ez-bet-v2/img/ez-slot-category-brand-mobile.png?v=1"
                                                />
                                            </picture>
                                            <span className="-menu-text-main -text-btn-image">
                                                ค่ายเกมส์
                                                <i className="fas fa-caret-down d-none d-lg-flex" />
                                            </span>
                                        </a>
                                        <a
                                            href="/listgameall/casino/"
                                            data-toggle="collapse"
                                            aria-expanded="false"
                                            className="x-category-button -is-collapse -category-brand collapsed js-brand d-lg-flex d-none  -category-button-v2 -hoverable "
                                        >
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="/build/web/ez-bet-v2/img/ez-slot-category-brand.webp?v=3"
                                                />
                                                <source
                                                    type="image/png?v=1"
                                                    srcSet="/build/web/ez-bet-v2/img/ez-slot-category-brand.png?v=1"
                                                />
                                                <img
                                                    alt=" cover image png"
                                                    className="-img -default"
                                                    src="/build/web/ez-bet-v2/img/ez-slot-category-brand.png?v=1"
                                                />
                                            </picture>
                                            <picture>
                                                <source
                                                    type="image/webp"
                                                    srcSet="/build/web/ez-bet-v2/img/ez-slot-category-brand-hover.webp?v=3"
                                                />
                                                <source
                                                    type="image/png?v=1"
                                                    srcSet="/build/web/ez-bet-v2/img/ez-slot-category-brand-hover.webp?v=3"
                                                />
                                                <img
                                                    alt=" cover image png"
                                                    className="-img -hover"
                                                       srcSet="/build/web/ez-bet-v2/img/ez-slot-category-brand-hover.webp?v=3"
                                                />
                                            </picture>
                                            <span className="-menu-text-main -text-btn-image">
                                                ค่ายเกมส์
                                                <i className="fas fa-caret-down d-none d-lg-flex" />
                                            </span>
                                        </a>
                                    </li>
                                    <li className="-list-parent nav-item">
                                        <div className="d-lg-block d-none">
                                            <a
                                                href="/listgameall/lotto/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-lotto  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-lotto.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-lotto.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -default"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-lotto.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-lotto-hover.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-lotto-hover.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -hover"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-lotto-hover.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">หวย</span>
                                            </a>
                                        </div>
                                        <div className="d-lg-none d-block w-100">
                                            <a
                                             href="/listgameall/lotto/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-lotto  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-lotto-mobile.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-lotto-mobile.png?v=1"
                                                    />
                                                    <img
                                                        alt="cover image png"
                                                        className="-img-mobile"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-lotto-mobile.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">หวย</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item">
                                        <div className="d-lg-block d-none">
                                            <a
                                                href="/listgameall/casino/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-casino  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-casino.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-casino.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -default"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-casino.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-casino-hover.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-casino-hover.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -hover"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-casino-hover.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">คาสิโนสด</span>
                                            </a>
                                        </div>
                                        <div className="d-lg-none d-block w-100">
                                            <a
                                               href="/listgameall/casino/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-casino  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-casino-mobile.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-casino-mobile.png?v=1"
                                                    />
                                                    <img
                                                        alt="cover image png"
                                                        className="-img-mobile"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-casino-mobile.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">คาสิโนสด</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item">
                                        <div className="d-lg-block d-none">
                                            <a
                                                href="/listgameall/sport/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                className="x-category-button -category-sport  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-sport.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-sport.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -default"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-sport.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-sport-hover.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-sport-hover.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -hover"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-sport-hover.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">สปอร์ต</span>
                                            </a>
                                        </div>
                                        <div className="d-lg-none d-block w-100">
                                            <a
                                                href="/listgameall/sport/"
                                                data-target=".js-game-container"
                                                data-href-push-state="/sport"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-sport  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-sport-mobile.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-sport-mobile.png?v=1"
                                                    />
                                                    <img
                                                        alt="cover image png"
                                                        className="-img-mobile"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-sport-mobile.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">สปอร์ต</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item">
                                        <div className="d-lg-block d-none">
                                            <a
                                                 href="/listgameall/skillgame/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-skill-game  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-skill-game.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-skill-game.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -default"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-skill-game.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-skill-game-hover.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-skill-game-hover.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -hover"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-skill-game-hover.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">สกิลเกมส์</span>
                                            </a>
                                        </div>
                                        <div className="d-lg-none d-block w-100">
                                            <a
                                                 href="/listgameall/skillgame/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-skill-game  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-skill-game-mobile.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-skill-game-mobile.png?v=1"
                                                    />
                                                    <img
                                                        alt="cover image png"
                                                        className="-img-mobile"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-skill-game-mobile.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">สกิลเกมส์</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item">
                                        <div className="d-lg-block d-none">
                                            <a
                                                href="/listgameall/fishing/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-fishing-game  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -default"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game.png?v=1"
                                                    />
                                                </picture>
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game-hover.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game-hover.png?v=1"
                                                    />
                                                    <img
                                                        alt=" cover image png"
                                                        className="-img -hover"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game-hover.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">ยิงปลา</span>
                                            </a>
                                        </div>
                                        <div className="d-lg-none d-block w-100">
                                            <a
                                                   href="/listgameall/fishing/"
                                                data-target=".js-game-container"
                                                data-menu-container=".js-menu-container"
                                                data-loading="_onLoading_"
                                                className="x-category-button -category-fishing-game  -category-button-v2 -hoverable "
                                            >
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game-mobile.webp?v=3"
                                                    />
                                                    <source
                                                        type="image/png?v=1"
                                                        srcSet="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game-mobile.png?v=1"
                                                    />
                                                    <img
                                                        alt="cover image png"
                                                        className="-img-mobile"
                                                        src="/build/web/ez-bet-v2/img/ez-slot-category-fishing-game-mobile.png?v=1"
                                                    />
                                                </picture>
                                                <span className="-menu-text-main -text-btn-image">ยิงปลา</span>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                                <div className="d-lg-none">
                                    <div className="-menu-parent -menu-mobile navbar-nav js-menu-container-mobile">
                                        <div className="-list-parent"></div>
                                    </div>
                                </div>
                            </nav>

                            {/* <nav className="nav-menu" id="navbarCategory">
                                <ul
                                    className="-menu-parent navbar-nav js-menu-container"
                                    id="accordion-games"
                                >
                                    <li className="-list-parent nav-item -category-type">
                                        <button
                                            type="button"
                                            className="-menu-btn -parent nav-link collapse show  js-type"
                                            data-toggle="collapse"
                                            data-target="#collapse-type"
                                            aria-expanded="false"
                                            aria-controls="collapse-type"
                                        >
                                            <div className="-img-category" />
                                            <div className="-menu-text">
                                                <span className="-menu-text-main">หมวดหมู่</span>
                                                <span className="-menu-text-sup d-none d-lg-block">2 ประเภท</span>
                                            </div>
                                            <i className="fas fa-chevron-down d-none d-lg-flex" />
                                        </button>
                                        <div
                                            id="collapse-type"
                                            className="-menu-child collapse"
                                            data-parent="#accordion-games"
                                        >
                                            <a
                                                type="button"
                                                className="-menu-btn btn-block -child"
                                                href={"/listgameall/slot/"}
                                            >
                                                <span className="-menu-text-child">เกมส์ฮิต</span>
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item -category-brand">
                                        <button
                                            type="button"
                                            id="btnbrand"
                                            data-toggle="collapse"
                                            data-target="#collapse-brand"
                                            aria-expanded="false"
                                            aria-controls="collapse-brand"
                                            style={{ display: "none" }}
                                        >
                                        </button>
                                        <button
                                            type="button"
                                            className="-menu-btn -parent nav-link collapsed js-brand"
                                            onClick={(e) => {
                                                this.buttoncheck(3);
                                            }}
                                        >
                                            <div className="-img-category" />
                                            <div className="-menu-text">
                                                <span className="-menu-text-main">ค่ายเกมส์</span>
                                                <span className="-menu-text-sup d-none d-lg-block">{this.state.categorylist.length} ค่าย</span>
                                            </div>
                                            <i className="fas fa-chevron-down d-none d-lg-flex" />
                                        </button>
                                        <div
                                            id="collapse-brand"
                                            className="-menu-child collapse"
                                            data-parent="#accordion-games"
                                        >

                                            {this.state.categorylist.map(slotlist => (
                                                <button
                                                    type="button"
                                                    className="-menu-btn btn-block -child"
                                                    onClick={(e) => this.reload(e, slotlist.partner, "slot")}
                                                >
                                                    <span className="-menu-text-child"> {slotlist.description}</span>

                                                </button>
                                            ))}
                                            <a
                                                href="#"
                                                target="_blank"
                                                className="-menu-btn btn-block -child"
                                            >
                                                <img
                                                    className="img-fluid -img-btn"
                                                    src="/build/web/ez-bet/img/simpleplay-logo.png"
                                                    alt="sp"
                                                />
                                            </a>
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item -category-casino">
                                        <button
                                            type="button"
                                            id="btncasino"
                                            data-toggle="collapse"
                                            data-target="#collapse-casino"
                                            aria-expanded="false"
                                            aria-controls="collapse-casino"
                                            style={{ display: "none" }}
                                        >
                                        </button>
                                        <button
                                            type="button"
                                            className="-menu-btn -parent nav-link collapsed js-brand"
                                            onClick={(e) => {
                                                this.buttoncheck(2);
                                            }}
                                        >

                                            <div className="-img-category" />
                                            <div className="-menu-text">
                                                <span className="-menu-text-main">คาสิโนสด</span>
                                                <span className="-menu-text-sup d-none d-lg-block">{this.state.casinolist.length} ค่าย</span>
                                            </div>
                                            <i className="fas fa-chevron-down d-none d-lg-flex" />
                                        </button>
                                        <div
                                            id="collapse-casino"
                                            className="-menu-child collapse"
                                            data-parent="#accordion-games"
                                        >
                                            {this.state.casinolist.map(casinolist => (
                                                <button
                                                    type="button"
                                                    className="-menu-btn btn-block -child "
                                                    onClick={(e) => this.reload(e, casinolist.partner, "casino")}
                                                >
                                                    <span className="-menu-text-child"> {casinolist.description}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item -category-sport">

                                        <button
                                            type="button"
                                            id="btnsport"
                                            data-toggle="collapse"
                                            data-target="#collapse-sport"
                                            aria-expanded="false"
                                            aria-controls="collapse-sport"
                                            style={{ display: "none" }}
                                        >
                                        </button>
                                        <button
                                            type="button"
                                            className="-menu-btn -parent nav-link collapsed js-brand"
                                            onClick={(e) => {
                                                this.buttoncheck(5);
                                            }}
                                        >
                                            <div className="-img-category" />
                                            <div className="-menu-text">
                                                <span className="-menu-text-main">สปอร์ต</span>
                                                <span className="-menu-text-sup d-none d-lg-block">{this.state.sportlist.length} ค่าย</span>
                                            </div>
                                            <i className="fas fa-chevron-down d-none d-lg-flex" />
                                        </button>
                                        <div
                                            id="collapse-sport"
                                            className="-menu-child collapse"
                                            data-parent="#accordion-games"
                                        >
                                            {this.state.sportlist.map(sportlist => (
                                                <button
                                                    type="button"
                                                    className="-menu-btn btn-block -child "
                                                    onClick={(e) => this.reload(e, sportlist.partner, "sport")}
                                                >
                                                    <span className="-menu-text-child"> {sportlist.description}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item -category-skill-game">

                                        <button
                                            type="button"
                                            id="btnfish"
                                            data-toggle="collapse"
                                            data-target="#collapse-fish"
                                            aria-expanded="false"
                                            aria-controls="collapse-fish"
                                            style={{ display: "none" }}
                                        >
                                        </button>
                                        <button
                                            type="button"
                                            className="-menu-btn -parent nav-link collapsed js-brand"
                                            onClick={(e) => {
                                                this.buttoncheck(8);
                                            }}
                                        >
                                            <div className="-img-category" />
                                            <div className="-menu-text">
                                                <span className="-menu-text-main">ยิงปลา</span>
                                                <span className="-menu-text-sup d-none d-lg-block">{this.state.fishlist.length} เกมส์</span>
                                            </div>

                                            <i className="fas fa-chevron-down d-none d-lg-flex" />
                                        </button>
                                        <div
                                            id="collapse-fish"
                                            className="-menu-child collapse"
                                            data-parent="#accordion-games"
                                        >
                                            {this.state.fishlist.map(fishlist => (
                                                <button
                                                    type="button"
                                                    className="-menu-btn btn-block -child "
                                                    onClick={(e) => this.reload(e, fishlist.partner, "fishing")}
                                                >
                                                    <span className="-menu-text-child"> {fishlist.description}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item -category-fishing-game">

                                        <button
                                            type="button"
                                            id="btnarcade"
                                            data-toggle="collapse"
                                            data-target="#collapse-arcade"
                                            aria-expanded="false"
                                            aria-controls="collapse-arcade"
                                            style={{ display: "none" }}
                                        >
                                        </button>
                                        <button
                                            type="button"
                                            className="-menu-btn -parent nav-link collapsed js-brand"
                                            onClick={(e) => {
                                                this.buttoncheck(4);
                                            }}
                                        >
                                            <div className="-img-category" />
                                            <div className="-menu-text">
                                                <span className="-menu-text-main">อาร์เคด</span>
                                                <span className="-menu-text-sup d-none d-lg-block">{this.state.arcadelist.length} เกมส์</span>
                                            </div>
                                            <i className="fas fa-chevron-down d-none d-lg-flex" />
                                        </button>

                                        <div
                                            id="collapse-arcade"
                                            className="-menu-child collapse"
                                            data-parent="#accordion-games"
                                        >
                                            {this.state.arcadelist.map(arcadelist => (
                                                <button
                                                    type="button"
                                                    className="-menu-btn btn-block -child "
                                                    onClick={(e) => this.reload(e, arcadelist.partner, "arcade")}
                                                >
                                                    <span className="-menu-text-child"> {arcadelist.description}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </li>
                                    <li className="-list-parent nav-item -category-type">

                                        <button
                                            type="button"
                                            id="btnlotto"
                                            data-toggle="collapse"
                                            data-target="#collapse-lotto"
                                            aria-expanded="false"
                                            aria-controls="collapse-lotto"
                                            style={{ display: "none" }}
                                        >
                                        </button>
                                        {/* <button
                                            type="button"
                                            className="-menu-btn -parent nav-link collapsed js-brand"
                                            onClick={(e) => {
                                                this.buttoncheck(11);
                                            }}
                                        >
                                            <div className="-img-category" />
                                            <div className="-menu-text">
                                                <span className="-menu-text-main">หวย</span>
                                                <span className="-menu-text-sup d-none d-lg-block">{this.state.lottolist.length} เกมส์</span>
                                            </div>
                                            <i className="fas fa-chevron-down d-none d-lg-flex" />
                                        </button> 

                                        <div
                                            id="collapse-lotto"
                                            className="-menu-child collapse"
                                            data-parent="#accordion-games"
                                        >
                                            {this.state.lottolist.map(lottolist => (
                                                <button
                                                    type="button"
                                                    className="-menu-btn btn-block -child "
                                                    onClick={(e) => this.reload(e, lottolist.partner, "lotto")}
                                                >
                                                    <span className="-menu-text-child"> {lottolist.description}</span>
                                                </button>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </nav> */}
                        </div>
                        <div className="-games-list-container js-game-scroll-container js-game-container col"  >
                            <div className="-games-list-wrapper">
                                <br></br>
                                <h2 className="-game-title h3">
                                    {this.state.typepage} Games
                                    <span className="-sub-title">({this.state.slotlist.length} games)</span>
                                </h2>

                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <div className="-game-list-heading-container">
                                            <div className="-inner-wrapper">
                                                <div className="-overlay">
                                                    <a
                                                        href="#"
                                                        className="-title"
                                                        target="_blank"
                                                        rel="nofollow noopener"
                                                    >
                                                        Random
                                                        <br />
                                                        Game
                                                    </a>
                                                    <img
                                                        className="-animate-img -pilot-tiny"
                                                        src="/build/web/ez-bet/img/ez-slot-animate-pilot-tiny-good.png"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    {this.state.slotlist.map(listitem => (
                                        <li className="nav-item" >
                                            <a
                                                href={() => false}
                                                onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                className="nav-link js-account-approve-aware"
                                                rel="nofollow noopener"
                                            >
                                                <div
                                                    className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable"
                                                    data-status="-cannot-entry -untestable"
                                                >
                                                    <div className="-inner-wrapper">
                                                        <img
                                                            src={listitem.image}
                                                            className="-cover-img lazyload img-fluid"
                                                            alt="pg-soft cover image png"
                                                            width={249}
                                                            onError={(e) => {
                                                                e.target.src = 'https://play-lh.googleusercontent.com/8guUOMrhHPgiIvmE2x7tvTqwbxLlcVXvpauGxyuXeVq-Mf2L-OSHDPn0i0MY532i9l4' // some replacement image
                                                            }}
                                                            height={361}
                                                        />
                                                        <div className="-overlay">
                                                            <div className="-overlay-inner">
                                                                <div className="-wrapper-container">
                                                                    <button href={() => false}
                                                                        // onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}

                                                                        className="-btn -btn-play js-account-approve-aware"
                                                                        rel="nofollow noopener"
                                                                    >
                                                                        <i className="fas fa-play" />
                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                    </button>
                                                                    <button
                                                                        id="loginmode"

                                                                        href="#loginModal"
                                                                        className="-btn -btn-play js-account-approve-aware"
                                                                        data-toggle="modal"
                                                                        data-target="#loginModal"
                                                                        style={{ display: "none" }}
                                                                    >
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="-title">{listitem.description}</div>
                                                </div>
                                            </a>
                                        </li>
                                    ))}

                                    {/* {this.state.slotlist.map(listitem => (
                                        <li className="nav-item" >
                                            <a
                                                href={() => false}
                                                onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}
                                                className="nav-link js-account-approve-aware"
                                                rel="nofollow noopener"
                                            >
                                                <div
                                                    className="x-game-list-item-macro js-game-list-toggle -big -cannot-entry -untestable"
                                                    data-status="-cannot-entry -untestable"
                                                >
                                                    <div className="-inner-wrapper">
                                                        <img
                                                            src={listitem.image}
                                                            className="-cover-img lazyload img-fluid"
                                                            alt="pg-soft cover image png"
                                                            width={249}
                                                            height={361}
                                                        />
                                                        <div className="-overlay">
                                                            <div className="-overlay-inner">
                                                                <div className="-wrapper-container">
                                                                    <button href={() => false}
                                                                        // onClick={(e) => this.redeem(e, listitem.gameId, listitem.partner)}

                                                                        className="-btn -btn-play js-account-approve-aware"
                                                                        rel="nofollow noopener"
                                                                    >
                                                                        <i className="fas fa-play" />
                                                                        <span className="-text-btn">เข้าเล่น</span>
                                                                    </button>
                                                                    <button
                                                                        id="loginmode"

                                                                        href="#loginModal"
                                                                        className="-btn -btn-play js-account-approve-aware"
                                                                        data-toggle="modal"
                                                                        data-target="#loginModal"
                                                                        style={{ display: "none" }}
                                                                    >
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    ))} */}
                                </ul>
                            </div>
                        </div>
                    </section >
                </div >
            );
        } else {

        }
    }
}
export default App;
